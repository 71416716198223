function applyCountChanges() {
    // eslint-disable-next-line no-restricted-syntax
    for (const colorsEl of document.querySelectorAll('[data-color-class]')) {
        const available = parseInt(colorsEl.dataset.colorClass, 10);

        colorsEl.classList.remove(
            'text-primary',
            'text-warning',
            'text-success'
        );

        if (available < 5) {
            colorsEl.classList.add('text-primary');
        } else if (available < 20) {
            colorsEl.classList.add('text-warning');
        } else {
            colorsEl.classList.add('text-success');
        }
    }
}

function applyTimeChanges() {
    const now = Date.now();
    // eslint-disable-next-line no-restricted-syntax
    for (const recordedEl of document.querySelectorAll('[data-recorded]')) {
        const recorded = new Date(recordedEl.dataset.recorded);
        const diff = (now - recorded.getTime()) / 1000;

        recordedEl.classList.remove(
            'text-primary',
            'text-warning',
            'text-success'
        );

        if (diff > 10 * 60) {
            recordedEl.classList.add('text-primary');
            recordedEl.textContent = `(il y a trop longtemps !)`;
        } else if (diff > 2 * 60) {
            recordedEl.classList.add('text-warning');
            recordedEl.textContent = `(il y a ${Math.ceil(diff / 60)} min)`;
        } else {
            recordedEl.classList.add('text-success');
            recordedEl.textContent = `(il y a ${diff} sec)`;
        }
    }
}

applyCountChanges();
applyTimeChanges();

setInterval(applyTimeChanges, 5000);
